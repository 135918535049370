import React, { useEffect, useState } from "react";
import GeneralInput from "../../../../components/GeneralInput";
import { submitButton } from "../../../../components/DesignStandardize";
import axios from "axios";
import { apiUrl } from "../../../../ApiUrlAndToken";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import SendIcon from "@mui/icons-material/Send";
import RemoveIcon from "@mui/icons-material/RemoveCircle";

const CreateRequest = ({ userID, project }) => {
  // console.log(userID,parseInt(project));
  const [rows, setRows] = useState([
    {
      id: 1,
      itemName: "",
      amountExpected: 0,
      expectedDate: "",
      thickness: "",
      size: "",
      unit: "",
      remarks: "",
    },
  ]);

  const addRow = () => {
    const newRow = {
      id: rows.length + 1,
      itemName: "",
      amountExpected: 0,
      expectedDate: "",
      thickness: "",
      size: "",
      unit: "",
      remarks: "",
    };
    setRows([...rows, newRow]);
  };

  const deleteRow = (id) => {
    const updatedRows = rows.filter((row) => row.id !== id);
    setRows(updatedRows);
  };

  const handleInputChange = (id, updatedField, value) => {
    const rowIndex = rows.findIndex((row) => row.id === id);
    if (rowIndex !== -1) {
      const updatedRows = [...rows];
      updatedRows[rowIndex][updatedField] = value;
      setRows(updatedRows);
    }
  };

  const [assignId, setAssignId] = useState(null);
  let projectID = parseInt(project);

  useEffect(() => {
    const fetchAssignId = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/assign/fetchAssignID/${userID}/${projectID}`
        );
        // console.log(response.data);
        const [assignIdFromResponse] = response.data;

        setAssignId(assignIdFromResponse);
      } catch (error) {
        console.error("Error fetching assign ID:", error);
      }
    };

    fetchAssignId();
  }, [userID, projectID]);

  const handleSubmit = async () => {
    try {
      const isValid = rows.every(
        (row) =>
          row.itemName.trim() !== "" &&
          row.amountExpected > 0 &&
          row.expectedDate !== "" &&
          row.thickness.trim() !== "" &&
          row.size.trim() !== ""
      );

      if (!isValid) {
        toast.error("Please fill in all required fields.");
        return;
      }

      const data = {
        ProjectID: projectID,
        AssignID: assignId,
        itemArr: rows.map((row) => [
          row.itemName,
          row.amountExpected,
          row.expectedDate,
          row.thickness,
          row.size,
          row.unit,
          row.remarks,
        ]),
      };
      console.log("data:", data);
      const response = await axios.post(
        `${apiUrl}/materialReq/createRequisition`,
        data
      );

      toast.success("Material Request Placed Successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="overflow-auto max-w-[320px] md:max-w-[520px] lg:max-w-[800px] max-h-96">
        <table className="">
          <thead>
            <tr className="text-base text-black text-center">
              <th />
              <th className="p-1">Item Name</th>
              <th className="p-1">Thickness</th>
              <th className="p-1">Size</th>
              <th className="p-1">Unit</th>
              <th className="p-1">Amount Requested</th>
              <th className="p-1">Date Expected</th>
              <th className="p-1">Remarks</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr key={row.id}>
                <td
                  className={`tooltip tooltip-right tooltip-success sticky left-0 z-40 bg-white ${
                    rows.length === 1 && "mt-2 h-10"
                  }`}
                  data-tip="Add row"
                >
                  <AddIcon
                    onClick={addRow}
                    className="mt-2 hover:text-green-500"
                  />
                </td>
                {rows.length > 1 && (
                  <td
                    className="tooltip tooltip-right tooltip-error sticky left-0 z-40 bg-white"
                    data-tip="Remove row"
                  >
                    <RemoveIcon
                      onClick={() => deleteRow(row.id)}
                      className="mt-2 hover:text-red-500"
                    />
                  </td>
                )}
                <td className="p-0">
                  <GeneralInput
                    type="text"
                    placeholder="Item Name"
                    value={row.itemName}
                    onChange={(ev) =>
                      handleInputChange(row.id, "itemName", ev.target.value)
                    }
                    table={1}
                  />
                </td>
                <td className="p-0">
                  <GeneralInput
                    type="text"
                    placeholder="Thickness"
                    value={row.thickness}
                    onChange={(ev) =>
                      handleInputChange(row.id, "thickness", ev.target.value)
                    }
                    table={1}
                  />
                </td>
                <td className="p-0">
                  <GeneralInput
                    type="text"
                    placeholder="Size"
                    value={row.size}
                    onChange={(ev) =>
                      handleInputChange(row.id, "size", ev.target.value)
                    }
                    table={1}
                  />
                </td>
                <td className="p-0">
                  <GeneralInput
                    type="text"
                    placeholder="Unit"
                    value={row.unit}
                    onChange={(ev) =>
                      handleInputChange(row.id, "unit", ev.target.value)
                    }
                    table={1}
                  />
                </td>
                <td className="p-0">
                  <GeneralInput
                    type="number"
                    placeholder="Receieved Amount"
                    value={row.amountExpected}
                    onChange={(ev) =>
                      handleInputChange(
                        row.id,
                        "amountExpected",
                        ev.target.value
                      )
                    }
                    min={0}
                    table={1}
                  />
                </td>
                <td className="p-0">
                  <GeneralInput
                    type="date"
                    value={row.expectedDate}
                    onChange={(ev) =>
                      handleInputChange(row.id, "expectedDate", ev.target.value)
                    }
                    table={1}
                  />
                </td>
                <td className="p-0">
                  <GeneralInput
                    type="text"
                    placeholder="Remarks"
                    value={row.remarks}
                    onChange={(ev) =>
                      handleInputChange(row.id, "remarks", ev.target.value)
                    }
                    table={1}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button className={`${submitButton} mt-3 mx-auto`} onClick={handleSubmit}>
        <SendIcon fontSize="1px" className="text-white -rotate-45 -mt-2" />
        Send Request{" "}
      </button>
    </>
  );
};

export default CreateRequest;
