import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl, isEmployeeLoggedIn } from "../../../../ApiUrlAndToken";
import EditIcon from "@mui/icons-material/BorderColor";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import DownloadIcon from "@mui/icons-material/CloudDownloadOutlined";
import FileDownloadIcon from "@mui/icons-material/FileDownloadOutlined";
import { hoverScale } from "../../../../components/DesignStandardize";
import EditItem from "./EditItem";
import EditRequest from "./EditRequest";
import UpdateStatusRequest from "./UpdateStatusRequest";
import AddMRRIcon from "@mui/icons-material/Addchart";
import CreateReport from "../report/CreateReport";
import UpdateStatusImageReq from "./UpdateStatusImageReq";
import UpdateImageRequest from "./UpdateImageRequest";
import CreateImageReport from "../report/CreateImageReport";
import ImageModal from "../../../../components/ImageModal";
import GeneralModal from "../../../../components/GeneralModal";
import { Button } from "@mui/material";
import { useParams } from "react-router-dom";

export const handleDownloadImageRequest = (picture) => {
  // Fetch the image URL
  fetch(`${apiUrl}/uploads/${picture}`)
    .then((response) => response.blob())
    .then((blob) => {
      // Create a temporary URL for the blob object
      const url = window.URL.createObjectURL(blob);
      // Create a temporary anchor element
      const downloadLink = document.createElement("a");
      // Set the href attribute to the temporary URL
      downloadLink.href = url;
      // Specify the download attribute and the file name
      downloadLink.setAttribute("download", picture);
      // Trigger a click event on the anchor element to start the download
      downloadLink.click();
      // Release the temporary URL
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error("Error downloading image:", error);
    });
};

const RequestList = ({ project, userID }) => {
  const { ProjectName } = useParams();

  const [materials, setMaterials] = useState([]);
  const [materialsImage, setMaterialsImage] = useState([]);
  const [assignData, setAssignData] = useState([]);

  // edit item
  const [eachItemEditModal, setEachItemEditModal] = useState(false);
  const [editItemID, setEditItemID] = useState(null);

  const toggleModal = () => {
    setEachItemEditModal((prevShowModal) => !prevShowModal);
  };
  const handleEditItem = (itemId) => {
    setEditItemID(itemId);
    toggleModal();
  };

  // edit request
  const [editRequestId, setEditRequestId] = useState(null);
  const [editRequestModal, setEditRequestModal] = useState(false);
  const toggleEditRequestModal = () => {
    setEditRequestModal((prevShowModal) => !prevShowModal);
  };
  const handleEditRequest = (requestId) => {
    setEditRequestId(requestId);
    toggleEditRequestModal();
  };

  const [editImgRequestId, setEditImgRequestId] = useState(null);
  const [editImgRequestModal, setEditImgRequestModal] = useState(false);
  const toggleEditImgRequestModal = () => {
    setEditImgRequestModal((prevShowModal) => !prevShowModal);
  };
  const handleEditImgRequest = (requestId) => {
    setEditImgRequestId(requestId);
    toggleEditImgRequestModal();
  };

  // add report
  const [addReportModal, setAddReportModal] = useState(false);
  const toggleAddReportModal = () => {
    setAddReportModal((prevShowModal) => !prevShowModal);
  };
  const handleAddReport = (reportId) => {
    setEditRequestId(reportId);
    toggleAddReportModal();
  };
  //add image in the report
  const [addImageReportModal, setAddImageReportModal] = useState(false);
  const toggleAddImageReportModal = () => {
    setAddImageReportModal((prevShowModal) => !prevShowModal);
  };
  const handleAddImageReport = (reportId) => {
    setEditImgRequestId(reportId);
    toggleAddImageReportModal();
  };

  const fetchAssignUserData = async () => {
    try {
      const assignDataResponse = await axios.get(
        `${apiUrl}/assign/fetchAssignIDUserID/${project}`
      );
      setAssignData(assignDataResponse.data);
    } catch (error) {
      console.error("Error fetching assign data:", error);
    }
  };

  const getAllRequests = async () => {
    try {
      const responseMaterials = await axios.get(
        `${apiUrl}/materialReq/getAllMRbyProjectID/${project}`
      );

      // Iterate over materials and add UserID
      const updatedMaterials = responseMaterials.data.requestItemArr.map(
        (material) => {
          const assignInfo = assignData.find(
            (assign) => assign.AssignID === material.AssignedID
          );
          if (assignInfo) {
            return { ...material, UserID: assignInfo.UserID };
          } else {
            return material;
          }
        }
      );

      setMaterials(updatedMaterials);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllImageRequests = async () => {
    try {
      const response = await axios.get(`${apiUrl}/request/project/${project}`);
      const imageRequests = response.data;

      // Fetch user information for each AssignID
      const userPromises = imageRequests.map(async (imageRequest) => {
        try {
          const userResponse = await axios.get(
            `${apiUrl}/assign/fetchUserIDName/${imageRequest.AssignID}`
          );

          return {
            ...imageRequest,
            userName: userResponse.data.Name,
            UserID: userResponse.data.UserID,
          };
        } catch (error) {
          console.log("Error fetching user info:", error);
          return { ...imageRequest, user: null }; // Handle error case
        }
      });

      // Wait for all user information requests to complete
      const imageRequestsWithUser = await Promise.all(userPromises);

      setMaterialsImage(imageRequestsWithUser);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAssignUserData();
  }, [project]);

  useEffect(() => {
    if (assignData.length > 0) {
      getAllRequests();
    }
  }, [assignData]);

  useEffect(() => {
    getAllImageRequests();
  }, [project]);

  const [modalImageUrl, setModalImageUrl] = useState(null);
  const openModal = (imageUrl) => {
    setModalImageUrl(imageUrl);
  };
  const closeModal = () => {
    setModalImageUrl(null);
  };

  const columns = [
    {
      header: "Item Name",
      accessorKey: "ItemName",
    },
    {
      header: "Thickness",
      accessorKey: "Thickness",
    },
    {
      header: "Size",
      accessorKey: "SIZE",
    },
    {
      header: "Unit",
      accessorKey: "Unit",
    },
    {
      header: "Amount",
      accessorKey: "AmountExpected",
    },
    {
      header: "Expected Date",
      accessorKey: "ExpectedDate",
    },
    {
      header: "Remarks",
      accessorKey: "REMARKS",
    },
  ];

  const handleDownloadPDF = (columns, data, title) => {
    const win = window.open("", "_blank");

    if (win) {
      const htmlContent = `
        <html>
          <head>
            <title>${ProjectName + "-" + title}</title>
          </head>
          <body>
            <div style="text-align: center;">
              <h3><strong>Project</strong>: ${ProjectName}</h3>
              <h3><strong>Name</strong>: ${title}</h3>
            </div>
            <table border="1" style="border-collapse: collapse; width: 100%;">
              <thead>
                <tr>
                  ${columns
                    .map(
                      (col) =>
                        `<th style="padding: 8px; text-align: left;">${col.header}</th>`
                    )
                    .join("")}
                </tr>
              </thead>
              <tbody>
                ${data
                  .map(
                    (row) =>
                      `<tr>${columns
                        .map(
                          (col) =>
                            `<td style="padding: 8px;">${
                              row[col.accessorKey] || "N/A"
                            }</td>`
                        )
                        .join("")}</tr>`
                  )
                  .join("")}
              </tbody>
            </table>
          </body>
        </html>
      `;
      win.document.write(htmlContent);
      win.document.close();
      win.print();
    }
  };

  return (
    <>
      <div className="min-w-96 overflow-x-auto h-screen">
        <div className="my-2 flex flex-col gap-3">
          {materials.map((material, index) => (
            <div
              key={index}
              className={`w-full border-[1px] border-solid rounded-lg flex flex-col gap-3 hover:shadow p-2 ${
                material.reqStatus === 0
                  ? "bg-slate-50"
                  : material.reqStatus === 1
                  ? "bg-green-100"
                  : "bg-red-100"
              }`}
            >
              <div className="flex gap-3">
                <div className="flex flex-col gap-1 text-left flex-1">
                  <p>
                    Request ID:{" "}
                    <span className="font-semibold">{material.MR_ID}</span>
                  </p>
                  <p>
                    Request from:{" "}
                    <span className="font-semibold">{material.Name}</span>
                  </p>
                </div>

                <div className="flex flex-col gap-1">
                  <div className="flex gap-3">
                    <button
                      className="mt-[-2px] "
                      onClick={() =>
                        handleDownloadPDF(columns, material.Data, material.Name)
                      }
                    >
                      <FileDownloadIcon />
                    </button>

                    {/* Status badge */}
                    <div
                      className={`badge ${
                        material.reqStatus === 0
                          ? "badge-info"
                          : material.reqStatus === 1
                          ? "badge-success"
                          : "badge-error"
                      } gap-2 ml-auto`}
                    >
                      {material.reqStatus === 0
                        ? "Pending"
                        : material.reqStatus === 1
                        ? "Approved"
                        : "Disapproved"}
                    </div>
                  </div>

                  {/* Button to add report */}
                  {material.UserID === userID &&
                    isEmployeeLoggedIn &&
                    material.reqStatus === 1 && (
                      <div
                        onClick={() => {
                          handleAddReport(material.MR_ID);
                        }}
                        className={`btn btn-outline btn-xs text-[12px]`}
                      >
                        <AddMRRIcon fontSize="small" /> Add Report
                      </div>
                    )}
                </div>

                {/* Button to edit the request */}
                {material.UserID === userID &&
                  isEmployeeLoggedIn &&
                  material.reqStatus === 0 && (
                    <button
                      className="mb-auto"
                      onClick={() => {
                        handleEditRequest(material.MR_ID);
                      }}
                    >
                      <EditIcon
                        className="hover:text-blue-500 text-black"
                        fontSize="inherit"
                      />
                    </button>
                  )}
              </div>

              <UpdateStatusRequest
                MR_ID={material.MR_ID}
                MR_Status={material.reqStatus}
              />

              <div className="overflow-x-auto">
                <table className="table text-center border border-slate-300">
                  <thead>
                    <tr className="font-semibold text-black border-b border-slate-300">
                      <th className="p-1 w-16">Item Name</th>
                      <th className="p-1 border-x border-slate-300">
                        Thickness
                      </th>
                      <th className="p-1">Size</th>
                      <th className="p-1 border-x border-slate-300">Unit</th>
                      <th className="p-1 border-x border-slate-300">Amount</th>
                      <th className="p-1 border-x border-slate-300">
                        Expected Date
                      </th>
                      <th className="p-1 border-x border-slate-300">Remarks</th>
                      {material.UserID === userID &&
                        material.reqStatus === 0 && (
                          <th className="p-1 border-l border-slate-300">
                            Edit
                          </th>
                        )}
                    </tr>
                  </thead>
                  {material.Data.length > 0 &&
                    material.Data.map((item, i) => (
                      <tbody key={i}>
                        <tr className="border-b border-slate-300">
                          <td className="p-1 w-16 text-left">
                            {item.ItemName}
                          </td>
                          <td className="p-1 border-x border-slate-300">
                            {item.Thickness}
                          </td>
                          <td className="p-1">{item.SIZE}</td>
                          <td className="p-1 border-x border-slate-300">
                            {item.Unit || "N/A"}
                          </td>
                          <td className="p-1 border-x border-slate-300">
                            {item.AmountExpected}
                          </td>
                          <td className="p-1 border-x border-slate-300">
                            {item.ExpectedDate}
                          </td>
                          <td className="p-1">{item.REMARKS}</td>
                          {/* Button to edit the each item */}
                          {material.UserID === userID &&
                            material.reqStatus === 0 && (
                              <td className="p-1 border-l border-slate-300">
                                <button
                                  onClick={() => {
                                    handleEditItem(item.RequestedItemID);
                                  }}
                                >
                                  <EditIcon
                                    className="hover:text-blue-500 text-black"
                                    fontSize="inherit"
                                  />
                                </button>
                              </td>
                            )}
                        </tr>
                      </tbody>
                    ))}
                </table>
              </div>
            </div>
          ))}

          {materialsImage.map((material, index) => (
            <div
              key={index}
              className={`w-full border-[1px] border-solid rounded-lg flex flex-col gap-3 hover:shadow p-2 hover:cursor-pointer ${
                material.ReqStatus === 0
                  ? "bg-slate-50"
                  : material.ReqStatus === 1
                  ? "bg-green-100"
                  : "bg-red-100"
              }`}
            >
              <div className="flex gap-3">
                <div className="flex flex-col gap-1 text-left flex-1">
                  <p>
                    Request ID: Image
                    <span className="font-semibold">{material.id}</span>
                  </p>
                  <p>
                    Request from:{" "}
                    <span className="font-semibold">{material.userName}</span>
                  </p>
                </div>

                <div className="flex flex-col gap-1">
                  <div className="flex gap-3">
                    {/* Status badge */}
                    <div
                      className={`badge ${
                        material.ReqStatus === 0
                          ? "badge-info"
                          : material.ReqStatus === 1
                          ? "badge-success"
                          : "badge-error"
                      } gap-2 ml-auto`}
                    >
                      {material.ReqStatus === 0
                        ? "Pending"
                        : material.ReqStatus === 1
                        ? "Approved"
                        : "Disapproved"}
                    </div>

                    {/* Button to download the request */}
                    <button
                      onClick={() =>
                        handleDownloadImageRequest(material.Picture)
                      }
                    >
                      <DownloadIcon className="hover:bg-green-700 hover:text-white p-[2px] rounded-xl text-black" />
                    </button>
                  </div>
                  {/* Button to add report */}
                  {material.UserID === userID &&
                    isEmployeeLoggedIn &&
                    material.ReqStatus === 1 && (
                      <div
                        onClick={() => {
                          handleAddImageReport(material.id);
                        }}
                        className={`btn btn-outline btn-xs text-[12px]`}
                      >
                        <AddMRRIcon fontSize="small" /> Add Report
                      </div>
                    )}
                </div>

                {/* Button to edit the request */}
                {material.UserID === userID &&
                  isEmployeeLoggedIn &&
                  material.ReqStatus === 0 && (
                    <button
                      className="mb-auto"
                      onClick={() => {
                        handleEditImgRequest(material.id);
                      }}
                    >
                      <EditIcon
                        className="hover:text-blue-500 text-black"
                        fontSize="inherit"
                      />
                    </button>
                  )}
              </div>

              <UpdateStatusImageReq
                MR_ID={material.id}
                MR_Status={material.ReqStatus}
              />

              {material.Picture && (
                <img
                  src={`${apiUrl}/uploads/${material.Picture}`}
                  alt="RequestdMaterials"
                  onClick={() =>
                    openModal(`${apiUrl}/uploads/${material.Picture}`)
                  }
                />
              )}
            </div>
          ))}
        </div>
        <div className="mb-64" />
      </div>

      {/* modal to edit each item */}
      {eachItemEditModal && editItemID && (
        <GeneralModal title="Edit Item Details" onClose={toggleModal}>
          <EditItem itemId={editItemID} onCancel={() => setEditItemID(null)} />
        </GeneralModal>
      )}

      {/* modal to edit request */}
      {editRequestModal && editRequestId && (
        <GeneralModal title="Edit Request" onClose={toggleEditRequestModal}>
          <EditRequest
            editID={editRequestId}
            onCancel={() => {
              setEditRequestId(null);
            }}
          />
        </GeneralModal>
      )}

      {/* modal to edit image request */}
      {editImgRequestModal && editImgRequestId && (
        <GeneralModal
          title="Edit Image Request"
          onClose={toggleEditImgRequestModal}
        >
          <UpdateImageRequest
            project={project}
            userID={userID}
            requestId={editImgRequestId}
          />
        </GeneralModal>
      )}

      {/* modal to add report */}
      {addReportModal && editRequestId && (
        <GeneralModal
          title={`Add Report for Request ID ${editRequestId}`}
          onClose={toggleAddReportModal}
        >
          <CreateReport
            userID={userID}
            requestID={editRequestId}
            onCancel={() => {
              setEditRequestId(null);
            }}
          />
        </GeneralModal>
      )}

      {/* add image report modal */}
      {addImageReportModal && editImgRequestId && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleAddImageReportModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex justify-between">
              <p className="text-xl text-blue-900 font-bold text-left">
                Add Report for <br /> Request ID Image {editImgRequestId}
              </p>
              <CloseIcon
                onClick={toggleAddImageReportModal}
                fontSize="1px"
                className={`hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
              />
            </div>

            <CreateImageReport
              project={project}
              userID={userID}
              requestID={editImgRequestId}
              onCancel={() => {
                setEditImgRequestId(null);
              }}
            />
          </div>
        </div>
      )}

      {modalImageUrl && (
        <ImageModal
          imageUrl={modalImageUrl}
          onClose={closeModal}
          title="Material Request"
        />
      )}
    </>
  );
};

export default RequestList;
